<template>
  <div class="mark-group">
    <div
      class="mark-group-left"
      id="t-scroll-mark-grou"
      v-loading="leftLoading"
    >
      <el-collapse v-if="tipGroupList.length > 0" v-model="collapseComputed">
        <el-collapse-item
          v-for="(item, i) in computedTipGroupList"
          :key="i"
          :name="item.id"
        >
          <template slot="title">
            {{ item.groupName }}
          </template>
          <div v-if="item.tipList">
            <div
              class="mark-item"
              :class="selectMark == ele.id ? 'select-mark-item' : ''"
              v-for="(ele, j) in item.tipList"
              :key="i + '-' + j"
              @click="clickMark(ele)"
            >
              {{ ele.tipName }}
              <el-tag
                type="danger"
                class="danger-tags"
                size="mini"
                effect="dark"
                v-if="ele.noRead"
              >
                {{ ele.noRead }}
              </el-tag>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <no-data v-else />
    </div>
    <div class="mark-group-center" id="t-scroll-mark-center">
      <ul v-if="groupList.length > 0">
        <li
          v-for="(item, i) in computedGroupList"
          :key="i"
          @click="clinckAccount(item.channelId)"
          :class="selectGroupId == item.channelId ? 'select-group' : ''"
        >
          <img
            :src="
              item.channelPhoto
                ? 'data:image/png;base64,' + item.channelPhoto
                : require('@/assets/imgs/p1.png')
            "
            :onerror="noPic"
            alt=""
          />
          <div>
            <div class="name">
              {{ item.channelTitle }}
            </div>
            <div class="user-name">
              {{ item.channelId }}
            </div>
          </div>
          <el-tag
            type="danger"
            v-if="item.noRead"
            class="danger-tags"
            size="mini"
            effect="dark"
          >
            {{ item.noRead }}
          </el-tag>
        </li>
      </ul>
      <no-data v-else />
    </div>
    <div class="mark-group-right">
      <groupPage v-if="selectGroupId" :groupId="selectGroupId" />
      <no-data v-else />
    </div>
  </div>
</template>

<script>
import { tipGroup } from '@/api/mark/menage'
import { tipChannel, tipList, channelTips } from '@/api/mark/markGroup'
import groupPage from './components/group/GroupDetail.vue'
import { scrollTopFun } from '@/plugins/logFunction'
export default {
  name: 'MarkGroup',
  components: {
    groupPage
  },
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/p1.png') + '"',
      // 标记数据
      tipGroupList: [],
      leftLoading: false,
      selectMark: null, //选中的标记
      // 群组数据
      groupList: [],
      centerLoading: false,
      selectGroupId: null, //选中的群组
      collapseArr: [],
      listScrollTop: 0,
      groupScrollTop: 0
    }
  },
  computed: {
    collapseComputed() {
      let a = []
      let b = this.tipGroupList.map(item => {
        return item.id
      })
      return a.concat(b)
    },
    noReadObj() {
      return this.$store.state.windowData.noRead
    },
    computedTipGroupList() {
      this.tipGroupList.forEach(item => {
        item.tipList.map(ele => {
          if (ele.noRead) {
            delete ele.noRead
          }
        })
      })
      let arr = this.tipGroupList.map(item => {
        if (
          this.noReadObj &&
          this.noReadObj.tipChannelMap &&
          this.noReadObj.tipChannelMap.objMap
        ) {
          item.tipList.map(element => {
            for (let i in this.noReadObj.tipChannelMap.objMap) {
              if (i == element.id) {
                let a = 0
                for (let j in this.noReadObj.tipChannelMap.objMap[i]) {
                  a = a + parseInt(this.noReadObj.tipChannelMap.objMap[i][j])
                }
                element.noRead = a
              }
            }
            return element
          })
        }
        return item
      })
      return arr
    },
    computedGroupList() {
      this.groupList.forEach(item => {
        if (item.noRead) {
          delete item.noRead
        }
      })
      let arr = this.groupList.map(item => {
        if (
          this.noReadObj &&
          this.noReadObj.tipChannelMap &&
          this.noReadObj.tipChannelMap.objMap
        ) {
          for (let i in this.noReadObj.tipChannelMap.objMap) {
            for (let j in this.noReadObj.tipChannelMap.objMap[i]) {
              if (j == item.channelId) {
                item.noRead = this.noReadObj.tipChannelMap.objMap[i][j]
              }
            }
          }
        }
        return item
      })
      return arr
    }
  },
  created() {
    this.getTipGroup().then(() => {
      this.getTipList()
    })
  },
  activated() {
    scrollTopFun('t-scroll-mark-grou', 'set', this.listScrollTop)
    scrollTopFun('t-scroll-mark-center', 'set', this.groupScrollTop)
  },
  deactivated() {
    this.listScrollTop = scrollTopFun('t-scroll-mark-grou', 'get')
    this.groupScrollTop = scrollTopFun('t-scroll-mark-center', 'get')
  },
  methods: {
    // 获取标记
    getTipGroup() {
      return new Promise((resolve, reject) => {
        this.leftLoading = true
        this.tipGroupList = []
        tipGroup(1, 1000).then(data => {
          this.tipGroupList = this.tipGroupList.concat(data.items)
          this.leftLoading = false
          resolve()
        })
      })
    },
    // 获取系统标记
    getTipList() {
      return new Promise((resolve, reject) => {
        tipList().then(data => {
          if (data && data.length > 0) {
            let obj = {
              id: 'systemTag',
              groupName: '系统标记',
              tipList: data.map((item, i) => {
                return {
                  type: 'systemTag',
                  id: 'systemtipName' + i,
                  tipName: item
                }
              })
            }
            this.tipGroupList.push(obj)
          }
          resolve()
        })
      })
    },
    // 获取系统下的群组
    getChannelTips(tip) {
      this.centerLoading = true
      channelTips(tip).then(data => {
        this.groupList = data
        this.centerLoading = false
      })
    },
    // 获取群组
    getTipChannel(id) {
      return new Promise((resolve, reject) => {
        this.centerLoading = true
        tipChannel(id).then(data => {
          this.groupList = data
          this.centerLoading = false
          resolve()
        })
      })
    },
    // 选中的标记
    clickMark(row) {
      this.selectMark = row.id
      this.selectGroupId = null
      this.groupList = []
      if (row.type || row.type == 'systemTag') {
        this.getChannelTips(row.tipName)
      } else {
        this.getTipChannel(row.id)
      }
    },
    // 选中得群组
    clinckAccount(id) {
      this.selectGroupId = id
      // this.selectGroupId = '1003120111'
    }
  }
}
</script>

<style lang="scss" scoped>
.mark-group {
  width: 100%;
  height: calc(100vh - 79px);
  background: #f0f2f5;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .mark-group-left {
    width: 260px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 5px;
    background: white;
    padding: 0 5px;
    position: relative;
    box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
    ::v-deep .el-collapse-item__header {
      height: 40px;
      line-height: 40px;
      position: relative;
      padding-left: 20px;
      background: #f1f2fc;
      margin-top: 3px;
      border-radius: 5px 5px 0 0;
    }
    ::v-deep .el-collapse-item__arrow {
      position: absolute;
      left: 0;
    }
    ::v-deep .el-collapse-item__wrap {
      border-bottom: none;
    }
    ::v-deep .el-collapse-item__content {
      padding-bottom: 0;
      border: 1px solid #ebeef5;
      border-radius: 0 0 5px 5px;
    }
    .mark-item {
      height: 46px;
      line-height: 46px;
      border-bottom: 1px solid #e2e3e3;
      font-size: 14px;
      font-weight: 300;
      color: #050505;
      padding: 0 10px;
      cursor: pointer;
      position: relative;
      .danger-tags {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
      }
      &:hover {
        color: red;
      }
    }

    .select-mark-item {
      background: #5a67c6;
      color: white;
    }
  }
  .mark-group-center {
    width: 260px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 5px;
    background: white;
    padding: 0 5px;
    position: relative;
    box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
    ul {
      margin: 0;
      padding: 0;
      li {
        display: flex;
        padding: 10px 5px;
        border-bottom: 1px solid #f2f4f8;
        position: relative;
        cursor: pointer;
        img {
          align-self: center;
          width: 29px;
          height: 29px;
          border-radius: 5px;
        }
        > div {
          align-self: center;
          width: calc(100% - 30px);
          margin-left: 10px;
          font-size: 12px;
          font-weight: 400;
          color: #121111;
          > div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .name {
            margin-bottom: 3px;
          }
        }
        .danger-tags {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
        }
        &:hover {
          background: #d0dffd;
        }
      }
      .select-group {
        background: #d0dffd;
      }
    }
  }
  .mark-group-right {
    width: calc(100% - 535px);
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
    background: white;
    position: relative;
    box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
  }
}
</style>
